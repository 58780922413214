
import { defineComponent, reactive, ref } from "vue";
import { RouterName } from "@/router";
import { approvalService } from "@/service";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Index",
  setup() {
    const router = useRouter();
    const approvalTempList = ref<Approval.TempList>([]);
    const getApprovalTempList = async () => {
      try {
        const { data } = await approvalService.getApprovalTempList("1", "0");
        approvalTempList.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };
    getApprovalTempList();
    const unapprovedTotal = ref(0);
    const approvalMyRelation = async () => {
      const { data } = await approvalService.approvalMyRelation({
        state: "0",
        type: "1",
        page: 1,
        pagesize: 99999
      });
      unapprovedTotal.value = data.total;
    };
    approvalMyRelation();
    const toMyApproval = (tag: number) => {
      router.push({
        name: RouterName.MY_APPROVAL,
        query: {
          tag
        }
      });
    };
    const shareTempItem = reactive<Approval.TempInfo>({} as Approval.TempInfo);
    const popupShow = ref(false);
    const shareImg = (item: Approval.TempInfo) => {
      Object.assign(shareTempItem, item);
      popupShow.value = true;
    };
    const onShare = () => {
      // eslint-disable-next-line no-undef
      wx.miniProgram.postMessage({ data: { foo: "bar" } });
    };
    return {
      approvalTempList,
      toMyApproval,
      shareImg,
      unapprovedTotal,
      shareTempItem,
      popupShow,
      onShare
    };
  }
});
